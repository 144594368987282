/***** Main style *****/
body {
    font-size: 16px;
    color: #7A839E;
    line-height: 30px;
    font-family: 'Poppins', sans-serif;
}

/* no-gutters Class Rules */
.row.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.row.no-gutters>[class^="col-"],
.row.no-gutters>[class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
}

/***** h tags *****/
h1 {
    font-size: 78px;
    margin: 0;
}

h2 {
    font-size: 50px;
    margin: 0;
}

h3 {
    font-size: 38px;
    margin: 0;
}

h4 {
    font-size: 25px;
    margin: 0;
}

h5 {
    font-size: 20px;
    margin: 0;
}

h6 {
    font-size: 18px;
    margin: 0;
}

/***** color tags *****/
.purple-txt {
    color: #5D2883;
}

.light-purple-txt {
    color: #40417B;
}

.black-txt {
    color: #181818;
}

.light-black-txt {
    color: #42495B;
}

.white-txt {
    color: #ffffff;
}

.light-green-txt {
    color: #93ba1f;
}

.red-txt {
    color: #bf0f00;
}

.dark-blue-txt {
    color: #023958;
}

.light-blue-txt {
    color: #82cfe9;
}

.orange-txt {
    color: #fc4606;
}

.yellow-txt {
    color: #fdb602;
}

/***** bgcolor tags *****/
.purple-bg {
    background-color: #40356F;
}

.light-purple-bg {
    background-color: #40356F;
}

.light-black-bg {
    background-color: #42495B;
}

.black-bg {
    background-color: #181818;
}

.white-bg {
    background-color: #fff;
}

.grey-bg {
    background-color: #F4F5FA;
}


/***** Background Image *****/
.service-bg {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../images/brand-bg-1.png);
}

/***** button *****/
.gradient-btn {
    background-image: linear-gradient(to right, #40356F, #645FCE);
    color: #fff;
    font-size: 20px;
    padding: 13px 35px;
    border-radius: 5px;
    transition: 0.5s ease-in-out;
}

.gradient-btn:hover {
    color: #fff;
    background-image: linear-gradient(to right, #645FCE, #40356F);
}

.border-btn {
    background: transparent;
    color: #181818;
    border: 1px solid #181818;
    font-size: 20px;
    padding: 13px 35px;
    border-radius: 5px;
    transition: unset;
}

.border-btn:hover {
    background-color: #44346c;
    color: #fff;
    border: 1px solid transparent;
}

/***** Hyper links *****/
a {
    text-decoration: none;
    display: inline-block;
    color: #5D2883;
    transition: 0.5s ease-in-out;
}

a:hover {
    text-decoration: none;
    color: #5D2883;
}

/***** radius *****/
.radius2 {
    border-radius: 2px;
}

.radius5 {
    border-radius: 5px;
}

.radius10 {
    border-radius: 10px;
}

.radius25 {
    border-radius: 25px;
}

.lett1 {
    letter-spacing: 1px;
}

.lett2 {
    letter-spacing: 2px;
}

/* **** listing tags *****/
#footer ul,
.team-item-main ul,
.testimonial-item-main ul {
    margin: 0;
    padding: 0;
}

#footer li,
.team-item-main li,
.testimonial-item-main li {
    list-style: none;
    display: inline-block;
}

/***** section *****/
section.section {
    padding: 65px 0;
}

/***** font-weights *****/
.fw400 {
    font-weight: 400;
}

.fw500 {
    font-weight: 500;
}

.fw600 {
    font-weight: 600;
}

.fw700 {
    font-weight: 700;
}

.fw800 {
    font-weight: 800;
}

.fw900 {
    font-weight: 900;
}

/***** header *****/
.head-bg {
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 630px;
    background-image: url(../images/bg.png);
}

.navbar {
    padding: 0;
}

#header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1030;
}

/* #header .navbar-brand img {
    width: 80%;
} */

#header.fixed-top .navbar-brand img {
    width: 70%;
}

#header .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
}

#header .dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-image: linear-gradient(to right, #645FCE, #40356F);
}

#header .dropdown-item:hover {
    color: #fff;
    background-image: linear-gradient(to right, #645FCE, #40356F);
}

.home-banner {
    height: 123vh;
    display: flex;
    align-items: center;
}

.banner-content-item .semi-txt {
    font-size: 30px;
    line-height: 92px;
}

.banner-content-item {
    height: 100%;
    display: flex;
    align-items: center;
}

.navbar .navbar-nav li.nav-item a.nav-link {
    font-size: 18px;
    color: #40356F;
    padding: 10px 2px;
    margin: 0 23px;
    position: relative;
    transition: 0.5s ease-in-out;
}

.navbar .navbar-nav li.nav-item a.nav-link::after {
    content: '';
    position: absolute;
    background: #645FCE;
    height: 3px;
    width: 0;
    left: 0;
    bottom: 0;
    transition: 0.5s ease-in-out;
}

.navbar .navbar-nav li.nav-item a.nav-link:hover::after {
    width: 100%;
}

.head-contact {
    font-size: 20px;
    padding: 11px 32px;
    color: #40356F;
    border: 1px solid #40356F;
    border-radius: 5px;
    margin-left: 25px;
}

.head-contact:hover {
    background-color: #40356F;
    color: #fff;
}

#header.head-internal .head-contact:hover {
    background-color: transparent;
    color: #44346c;
}

#header.head-internal .fixed-top {
    background-color: #40356F;
    padding: 5px 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}



/***** about *****/
section.section.home-about {
    padding: 70px 0;
    background-color: #f5f8fd;
}

section.section.home-what-we-offer {
    padding: 70px 0 150px 0;
}

.home-about .content-item {
    padding-top: 50px;
}

.what-we-offer-item-main {
    margin-top: 70px;
}

.what-we-offer-item {
    padding: 30px 40px;
    margin-bottom: 30px;
    transition: 0.5s ease-in-out;
}

.what-we-offer-item:hover {
    box-shadow: 1px 1px 10px rgb(0 0 0 / 20%);
    background-color: #fff;
    cursor: pointer;
}

.home-what-we-offer {
    background-repeat: no-repeat;
}

/* Schedule Call */
.home.schedule {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 420px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-attachment: fixed;
    box-shadow: inset 0 0 0 1000px rgba(100, 95, 206, 0.3);
}

/* .home.schedule::before{content: ''; position: absolute; background-color: rgba(100,95,206,0.3); top: 0; right: 0; bottom: 0; left: 0; } */
.home.schedule .content-item .desc {
    color: rgba(255, 255, 255, 0.9);
}



/***** testimonials *****/
.testimonial-item-main {
    margin-top: 80px;
}

.testimonial-item-main .carousel-indicators {
    bottom: -50px;
}

.testimonial-item-main .carousel-indicators [data-bs-target] {
    background-color: #645FCE;
    height: 5px;
}

.owl-testi .item {
    border: 1px solid #cccccc;
    border-radius: 10px;
    padding: 0 50px 50px 50px;
}

.owl-testi .item .image img {
    width: 100px;
    height: 100px;
    border-radius: 20%;
}

.owl-testi .owl-item {
    padding: 60px 0;
}

.owl-testi .item .image {
    position: relative;
}

.owl-testi ul.ratings li {
    margin-right: 5px;
}

.owl-testi ul.ratings li i {
    color: #FFD500;
    font-size: 20px;
}


.add-item i {
    flex: 1;
    margin-top: 5px;
}

.add-item a {
    color: #fff;
    flex: 7;
}

/* .footer-strip{padding:15px 0; border-top:1px solid #707070;} */
.social {
    margin-top: 20px;
}

.social li a {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);
    width: 45px;
    height: 45px;
    border: 1px solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.social li a:hover {
    background-color: #F4AD02;
    border: 1px solid #F4AD02;
    color: #fff;
}

#footer {
    padding: 0px 0 0 0;
}

#footer {
    background-image: linear-gradient(to left, #645FCE, #40356f);
}

.foot-title {
    font-size: 20px;
    position: relative;
    padding-bottom: 0px;
    margin-top: 60px;
    letter-spacing: 2px;
}

.foot-content {
    margin-top: 30px;
}

.foot-content.first {
    margin-top: 0;
}

.foot-item.last {
    padding-left: 80px;
}

#footer .add-item {
    display: flex;
    margin-bottom: 20px;
}

#footer .add-item .icn {
    margin-right: 10px;
}

#footer .add-item .val a {
    color: #fff;
}

.copy-right-text {
    font-size: 20px;
    padding: 20px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    margin-top: 40px;
}

ul.foot-social li a {
    font-size: 20px;
    color: #fff;
}

ul.foot-social li {
    margin-right: 10px;
}

.foot-logo {
    margin-top: 63px;
}

/***** internal banner *****/
.internal-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 290px;
    margin-top: 130px;
    text-align: center;
    position: relative;
    background-image: url('../images/page-title-bg3.jpeg');
    background-position: top right;
}

.gmb-next-level .section-top h3 {
    position: relative;
    padding-bottom: 20px;
}

.gmb-next-level .section-top h3::after {
    content: '';
    position: absolute;
    background-color: #645FCE;
    height: 5px;
    width: 7%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

/***** web design *****/
section.gmb.web-design {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
}

.together-item-main {
    margin-top: 100px;
}

.together-item-inner {
    margin-bottom: 100px;
}

.together-item-inner:last-child {
    margin-bottom: 0;
}

.web-design-package .section-top a.package-contact {
    font-size: 20px;
    padding: 11px 32px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    margin-left: 25px;
}

.web-design-package .section-top a.package-contact:hover {
    background-color: #fff;
    color: #171717;
}

.gmb-next-level.web-design-package .section-top h3::after {
    background-color: #fff;
}


/* About */

.about .section-top h3 {
    position: relative;
    padding-bottom: 20px;
}

.about .section-top h3::after {
    content: '';
    position: absolute;
    background-color: #645FCE;
    height: 5px;
    width: 18%;
    bottom: 0;
    left: 0;
    margin: 0;
}

.about-values {
    padding: 80px 0;
}

.about ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.about li {
    padding-top: 20px;
    margin-left: 0;
}

.about ul li:before {
    content: '✓';
    font-weight: bold;
    padding-right: 8px;
    color: #645FCE;
}


/* Contact */

.contact .form-control {
    background-color: #f4f5fa;
    border: none;
    border-radius: 3px;
    margin: 30px 0;
}

/* Icons */

.icon-size {
    width: 75px;
}


/* Form Message */
p.form-messege.error {
    margin-top: 20px;
    color: #f10;
}

p.form-messege.success {
    color: green;
    margin-top: 20px;
    opacity: 0.8;
}

/* Map */

.map-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.map-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

/***** media queries *****/
/* @media (min-width: 1200px){
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1390px;
}
} */

@media (max-width: 1100px) {
    h1 {
        font-size: 50px;
    }

    .smi,
    .cd,
    .kr,
    .osli {
        position: initial;
        text-align: center !important;
        margin: 15px 0;
    }

    .head-bg {
        background-size: 170px;
    }

    .home-banner {
        height: 115vh;
    }

    .owl-client .image {
        width: 70px;
    }
}

@media (max-width : 900px) {
    .head-bg {
        background: none;
    }
}

@media (min-width: 1900px) {
    .head-bg {
        background-size: auto;
    }
}